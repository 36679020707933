import './App.css';
import Header from './assets/webAppAssets/header.png';
import Pradeep from './assets/webAppAssets/itsprade.png';
import { ReactComponent as TwitterLogo } from './assets/webAppAssets/twitter.svg';
import { ReactComponent as Logo } from './assets/webAppAssets/logo.svg';
import icons from './icon-data.js';
import { motion } from 'framer-motion';

function App() {

  return (
    <div className="App">
      <motion.div initial={{ scale: 3, y: -400, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} className="w-full -z-50 sm:h-[34rem] h-[32rem] bg-cover absolute top-0 left-0" style={{ backgroundImage: `url(${Header})` }}>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} className="flex flex-row-reverse pt-8 px-8 justify-between">
        <div className="flex flex-row self-center">
          <div className="border-r border-white/40 px-4">
            <a target="_blank" rel="noreferrer" href="https://twitter.com/intent/tweet?text=%E2%9A%A1%EF%B8%8F%20Check%20out%20this%20new%20icon%20library%20https%3A%2F%2Fwww.theicons.club%2F%20from%20%40itsprade%20">
              <motion.div whileHover={{ scale: 1.1 }}>< TwitterLogo /></motion.div>
            </a>
          </div>
          <img className="h-6 w-6 ml-4 rounded-full mx-3 self-center" src={Pradeep} alt="Profile" />
          <a target="_blank" rel="noreferrer" href="https://twitter.com/itsprade">
            <span className="text-base font-semibold text-white self-center hover:underline"> by @itsprade</span>
          </a>
        </div>
        <Logo />
      </motion.div>
      <div className="max-w-xl text-center mx-auto justify-center flex flex-col pt-16">
        <div className="self-center hidden">
          <Logo />
        </div>
        <motion.div initial={{ scale: 2, y: 300, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ duration: 0.6, ease: 'easeInOut' }} className="font-bold sm:text-5xl pt-5 sm:leading-[52px] text-3xl leading-9 text-white px-5">I just couldn't help creating another icon set.</motion.div>
        <motion.div initial={{ scale: 2, y: 400, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 0.7 }} transition={{ duration: 0.8, ease: 'easeInOut' }} className="text-xl text-white opacity-70 pt-5 px-9">Use them anywhere. Replace them with anything. Do whatever. Free icons added every week.</motion.div>
      </div>
      <motion.div initial={{ scale: 1.5, y: 500, opacity: 0 }} animate={{ scale: 1, y: 0, opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} className="w-full flex justify-center">
        <div className="flex flex-row flex-wrap sm:w-3/4 w-full sm:gap-5 gap-4 pt-16 justify-center mb-16">
          {icons.map((icon, index) => {
            return (
              <div key={index} className="relative group flex h-40 w-40 rounded-2xl bg-white drop-shadow-sm border">
                <div className="flex flex-col self-center space-b w-full p-4">
                  <div className="block group-hover:opacity-10 self-center">
                    <img src={icon.icon} alt="" />
                  </div>
                  <div className="block group-hover:opacity-10 w-full text-center pt-4 text-xs opacity-60">
                    {icon.iconName}
                  </div>
                </div>
                <motion.div whileHover={{ scale: 1.02 }} className="absolute px-3 hidden group-hover:block w-full bottom-0 mb-3">
                  <a download={icon.iconName} href={icon.icon}>
                    <div className="hover:bg-black border-black bg-gray-800 border-2 rounded-lg text-white text-sm py-3 px-4 w-auto text-center hover:cursor-pointer">
                      Download
                    </div>
                  </a>
                  {/* <CopyToClipboard text={ icon.icon }>
                  <div className="border-2 hover:border-gray-500 rounded-lg text-black text-sm mt-2 py-3 px-4 w-auto text-center hover:cursor-pointer">
                    Copy SVG
                  </div>
                  </CopyToClipboard> */}
                </motion.div>
              </div>
            )
          })}
        </div>
      </motion.div>
    </div>
  );
}

export default App;
