
const imgFromAssets = require.context('./assets', false, /\.svg/ );

const iconData = imgFromAssets
  .keys ()
  .map ( path => ({ icon: imgFromAssets ( path ), iconName: path.replace('./', '').split('.').reverse().slice(1).reverse().join('.') }) )


  console.log(iconData)

export default iconData;
